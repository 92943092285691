// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("/opt/build/repo/src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("/opt/build/repo/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-corsi-js": () => import("/opt/build/repo/src/pages/corsi.js" /* webpackChunkName: "component---src-pages-corsi-js" */),
  "component---src-pages-dove-trovarci-js": () => import("/opt/build/repo/src/pages/dove-trovarci.js" /* webpackChunkName: "component---src-pages-dove-trovarci-js" */),
  "component---src-pages-il-centro-js": () => import("/opt/build/repo/src/pages/il-centro.js" /* webpackChunkName: "component---src-pages-il-centro-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lezioni-js": () => import("/opt/build/repo/src/pages/lezioni.js" /* webpackChunkName: "component---src-pages-lezioni-js" */),
  "component---src-pages-prezzi-js": () => import("/opt/build/repo/src/pages/prezzi.js" /* webpackChunkName: "component---src-pages-prezzi-js" */)
}

